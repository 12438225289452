import React from 'react';
import { notification } from 'antd';
import { useDispatch } from 'react-redux';
import { NotificationPlacement } from 'antd/lib/notification';
import { IShowToastPayload } from '../../libs/models/ToastModels';
import { ToastActions } from '../../libs/store/actions/ToastActions';
import { NotificationTypes } from './NotificationsConstants';

export const Toast = (
  description: string,
  message: string,
  type: NotificationTypes,
  duration?: number,
  placement?: NotificationPlacement,
  onToastClose?: () => void,
) => {
  const dispatch = useDispatch();

  const dispatcher = {
    hideToast: () => dispatch(ToastActions.hideToast()),
  };

  const hideToast = () => dispatcher.hideToast();

  notification[type]({
    key: 'toast',
    message: message,
    description:
      duration === 0 ? (
        <>
          Best viewed at <b>67% Zoom </b> for windows
        </>
      ) : (
        description
      ),
    onClose: onToastClose ? onToastClose : hideToast,
    duration: duration,
    placement: placement,
    style: duration === 0 ? { bottom: '1rem' } : {},
    icon:
      duration === 0 ? (
        <>
          <img
            src={`${process.env.PUBLIC_URL}/config-icons/cbo/desktop-icon.svg`}
            alt="desktop-icon"
          />
        </>
      ) : (
        ''
      ),
  });
};
