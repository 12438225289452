/* eslint-disable max-lines */
import { API_ROUTE_CBO } from '../../ApiRoute';
import ApiService from '../../ApiService';
import ApiServiceBase from '../../ApiServiceBase';
import { ServerType, ServiceType } from '../../ServiceType.data';
import {
  IJiraInitiativesResponse,
  ILiftAndShiftObject,
  IJiraInitiativesPayload,
  IJiraEpicsResponse,
  IJiraEpicsPayload,
  IJiraTasksPayload,
  IJiraTasksResponse,
  ISummaryMetricsPayload,
  IJiraTaskListResponse,
  IJiraIssueResponse,
  IOperateEndgeIncidentPayload,
  ICarbonFootPrintResponse,
  IGreenCloudPayload,
  ILegacyDbData,
  ISummaryMetricsResp,
  IDEPAlertScanResponse,
  IDEPAlertScanPayload,
  IIncidentDetailsPayload,
  IIncidentDetails,
  ICodespacesResponse,
  IProblemInsightsAssignmentGroupData,
  IProblemInsightsRunDetailsData,
  IProblemInsightsPayload,
  IProblemGroupOverviewResponse,
  IKMSResponse,
  IActiveSummaryResponse,
  IIssueSummaryResponse,
  IRiskSummaryResponse,
  ITransitionTableResponseData,
  IJiraStoriesPayload,
  IJiraStoryListResponse,
  IAppsProgressionResponse,
  IServersProgressionResponse,
  IMigrationPayload,
  IAppsProgressionMonthlyResponse,
  IServersProgressionMonthlyResponse,
  IMigrationArcheTypeListResponse,
  ITCOInsightsResponse,
  ICostTrendResponse,
  ICostAllocationSummaryResponse,
  IBlockersAndCausesResponse,
  IAllocationTrendResponse,
  ICloudCostSummaryCostTrendResponse,
  IServersByUpgradeResponse,
  IDispositionsByWaveData,
  IOtherSQLPayload,
  ISQLDMLPayload,
  IWorkflowPayload,
  ISeverityVsCreatedOnResponse,
  IDefectAgeingResponse,
  INumberOfTestCasesResponse,
  ITableCountResponse,
  ITestRunStatusResponse,
  ITaxonomyEditorData,
} from '../../../models/cbo/EngagementSummaryGraphModel';
import {
  IArtifactsGraphPayload,
  IArtifactsLOCResponse,
  IArtifactsResponse,
  IDecompositionResponse,
  ISQLGraphDataResponse,
  IAllSQLGraphPayload,
  IExecutionMetricDetailsResponse,
  IWorkflowListPayload,
  IWorkflowReportResponse,
  IWorkflowResponse,
  ICodeQualityPayload,
  ICodeQuality,
} from '../../../models/cbo/EngagementSummaryGraphModel';
import {
  IApplicationDipositioning,
  IWavePlan,
} from '../../../../containers/CBOEngagementSummary/CboTrunorthGraphs/CboTrunorthConstants';
import {
  IDiscoveryMetrics,
  ISurveyMetrics,
} from '../../../../containers/CBOEngagementSummary/CboTrunorthGraphs/CboTrunorthConstants';
import { STORAGE_CONSTANTS, storage } from '../../LocalStorage';
import { CBOKeyAssets } from '../../../../containers/CBOSidebar/CBOSidebarConstants';
import { IProduct } from '../../../models/cbo/EngagementModels';
import {
  ILearningCenterPayload,
  ISummaryPractitionerDataResp,
  IDashboardCourseStatusResponse,
} from '../../../../containers/CBOEngagementSummary/operate/LearningCenter/LearningCenterConstants';

export class EngagementGraphService {
  private readonly cboService: ApiServiceBase = new ApiService(ServiceType.None, ServerType.cbo);
  private readonly localStackService: ApiServiceBase = new ApiService(
    ServiceType.None,
    ServerType.localStack,
  );

  private readonly cboProxyServer: ApiServiceBase = new ApiService(
    ServiceType.None,
    ServerType.cboProxyServer,
  );

  public getLiftAndShiftChart(): Promise<ILiftAndShiftObject> | ILiftAndShiftObject {
    return this.cboService.get({
      route: [`${API_ROUTE_CBO.GET_LIFT_AND_SHIFT}`],
    });
  }
  public getJiraInitiatives(
    data: IJiraInitiativesPayload,
  ): Promise<IJiraInitiativesResponse> | IJiraInitiativesResponse {
    return this.cboService.post(
      {
        route: [`${API_ROUTE_CBO.GET_JIRA_INITIATIVES}`],
      },
      data,
    );
  }

  public getJiraEpics(data: IJiraEpicsPayload): Promise<IJiraEpicsResponse> | IJiraEpicsResponse {
    return this.cboService.post(
      {
        route: [`${API_ROUTE_CBO.GET_JIRA_EPICS}`],
      },
      data,
    );
  }

  public getJiraTasks(data: IJiraTasksPayload): Promise<IJiraTasksResponse> | IJiraTasksResponse {
    return this.cboService.post(
      {
        route: [`${API_ROUTE_CBO.GET_JIRA_TASKS}`],
      },
      data,
    );
  }

  //Currently used Services for Jira//

  public getJiraCurrentSprintStoryList(
    data: IJiraStoriesPayload | IJiraTasksPayload,
  ): Promise<IJiraIssueResponse> | IJiraIssueResponse {
    const isEngagement = JSON.parse(
      storage.getItem(STORAGE_CONSTANTS.isEngagement) ?? 'false',
    ) as boolean;
    if (isEngagement) {
      return this.cboService.get({
        route: [
          `${API_ROUTE_CBO.GET_JIRA_SPRINT.replace(`:phase`, data?.phase ?? '').replace(
            `:projectId`,
            data?.engagementId!,
          )}`,
        ],
      });
    } else {
      return this.cboService.get({
        route: [
          `${API_ROUTE_CBO.GET_PURSUIT_JIRA_SPRINT.replace(`:phase`, data?.phase ?? '').replace(
            `:pursuitId`,
            data?.engagementId!,
          )}`,
        ],
      });
    }
  }
  public getJiraTableInitiatives(
    data: IJiraInitiativesPayload,
  ): Promise<IJiraIssueResponse> | IJiraIssueResponse {
    const isEngagement = JSON.parse(
      storage.getItem(STORAGE_CONSTANTS.isEngagement) ?? 'false',
    ) as boolean;
    if (isEngagement) {
      return this.cboService.get({
        route: [
          `${API_ROUTE_CBO.GET_JIRA_INITIATIVE.replace(`:phase`, data?.phase ?? '').replace(
            `:projectId`,
            data?.engagementId!,
          )}`,
        ],
      });
    } else {
      return this.cboService.get({
        route: [
          `${API_ROUTE_CBO.GET_PURSUIT_JIRA_INITIATIVE.replace(`:phase`, data?.phase ?? '').replace(
            `:pursuitId`,
            data?.engagementId!,
          )}`,
        ],
      });
    }
  }

  public getJiraTableEpics(
    data: IJiraEpicsPayload,
  ): Promise<IJiraIssueResponse> | IJiraIssueResponse {
    const isEngagement = JSON.parse(
      storage.getItem(STORAGE_CONSTANTS.isEngagement) ?? 'false',
    ) as boolean;
    if (isEngagement) {
      return this.cboService.get({
        route: [
          `${API_ROUTE_CBO.GET_JIRA_EPIC.replace(`:phase`, data?.phase ?? '').replace(
            `:projectId`,
            data?.engagementId!,
          )}`,
        ],
        query: { type: 'epic', id: data.initiativeId },
      });
    } else {
      return this.cboService.get({
        route: [
          `${API_ROUTE_CBO.GET_PURSUIT_JIRA_EPIC.replace(`:phase`, data?.phase ?? '').replace(
            `:pursuitId`,
            data?.engagementId!,
          )}`,
        ],
        query: { type: 'epic', id: data.initiativeId },
      });
    }
  }

  public getJiraTableStoryList(
    data: IJiraStoriesPayload,
  ): Promise<IJiraStoryListResponse> | IJiraStoryListResponse {
    const isEngagement = JSON.parse(
      storage.getItem(STORAGE_CONSTANTS.isEngagement) ?? 'false',
    ) as boolean;
    if (isEngagement) {
      return this.cboService.get({
        route: [
          `${API_ROUTE_CBO.GET_JIRA_STORY.replace(`:phase`, data?.phase ?? '').replace(
            `:projectId`,
            data?.engagementId!,
          )}`,
        ],
        query: { type: 'story', id: data.epicId },
      });
    } else {
      return this.cboService.get({
        route: [
          `${API_ROUTE_CBO.GET_PURSUIT_JIRA_STORY.replace(`:phase`, data?.phase ?? '').replace(
            `:pursuitId`,
            data?.engagementId!,
          )}`,
        ],
        query: { type: 'story', id: data.epicId },
      });
    }
  }

  public getJiraTableTaskList(
    data: IJiraTasksPayload,
  ): Promise<IJiraTaskListResponse> | IJiraTaskListResponse {
    const isEngagement = JSON.parse(
      storage.getItem(STORAGE_CONSTANTS.isEngagement) ?? 'false',
    ) as boolean;
    if (isEngagement) {
      return this.cboService.get({
        route: [
          `${API_ROUTE_CBO.GET_JIRA_TASK.replace(`:phase`, data?.phase ?? '').replace(
            `:projectId`,
            data?.engagementId!,
          )}`,
        ],
        query:
          data?.phase === 'migrate'
            ? { type: 'task', id: data.storyId }
            : { type: 'task', id: data?.epicId },
      });
    } else {
      return this.cboService.get({
        route: [
          `${API_ROUTE_CBO.GET_PURSUIT_JIRA_TASK.replace(`:phase`, data?.phase ?? '').replace(
            `:pursuitId`,
            data?.engagementId!,
          )}`,
        ],
        query:
          data?.phase === 'migrate'
            ? { type: 'task', id: data.storyId }
            : { type: 'task', id: data?.epicId },
      });
    }
  }

  //End of Currently used Services for Jira//

  //Start: Innowake Graph API
  public getSQLGraph(
    data: IAllSQLGraphPayload | ISQLDMLPayload | IOtherSQLPayload,
  ): Promise<ISQLGraphDataResponse> | ISQLGraphDataResponse {
    const isEngagement = JSON.parse(
      storage.getItem(STORAGE_CONSTANTS.isEngagement) ?? 'false',
    ) as boolean;
    const payload = { ...data };
    delete payload.selectedEngagement;
    if (isEngagement) {
      return this.cboService.post(
        {
          route: [
            `${API_ROUTE_CBO.GET_ENG_SQL_DATA.replace(`:projectId`, data?.selectedEngagement!)}`,
          ],
        },
        payload,
      );
    }
    return this.cboService.post(
      {
        route: [
          `${API_ROUTE_CBO.GET_PURSUIT_SQL_DATA.replace(`:projectId`, data?.selectedEngagement!)}`,
        ],
      },
      payload,
    );
  }

  public getDecompositionGraph(
    data: string,
  ): Promise<IDecompositionResponse> | IDecompositionResponse {
    const isEngagement = JSON.parse(
      storage.getItem(STORAGE_CONSTANTS.isEngagement) ?? 'false',
    ) as boolean;

    if (isEngagement) {
      return this.cboService.get({
        route: [`${API_ROUTE_CBO.GET_ENG_DECOMPOSITION_GRAPH_DATA.replace(`:projectId`, data!)}`],
      });
    }
    return this.cboService.get({
      route: [`${API_ROUTE_CBO.GET_PURSUIT_DECOMPOSITION_GRAPH_DATA.replace(`:projectId`, data!)}`],
    });
  }

  public getMissingArtifactsGraphData(
    data: IArtifactsGraphPayload,
  ): Promise<IArtifactsResponse> | IArtifactsResponse {
    const isEngagement = JSON.parse(
      storage.getItem(STORAGE_CONSTANTS.isEngagement) ?? 'false',
    ) as boolean;

    const payload = { ...data };
    delete payload.selectedEngagement;
    if (isEngagement) {
      return this.cboService.post(
        {
          route: [
            `${API_ROUTE_CBO.GET_ENG_ARTIFACTS_GRAPH_DATA.replace(
              `:projectId`,
              data?.selectedEngagement!,
            )}`,
          ],
        },
        payload,
      );
    }
    return this.cboService.post(
      {
        route: [
          `${API_ROUTE_CBO.GET_PURSUIT_ARTIFACTS_GRAPH_DATA.replace(
            `:projectId`,
            data?.selectedEngagement!,
          )}`,
        ],
      },
      payload,
    );
  }

  public getAnalyzedArtifactsGraphData(
    data: IArtifactsGraphPayload,
  ): Promise<IArtifactsResponse> | IArtifactsResponse {
    const isEngagement = JSON.parse(
      storage.getItem(STORAGE_CONSTANTS.isEngagement) ?? 'false',
    ) as boolean;
    const payload = { ...data };
    delete payload.selectedEngagement;
    if (isEngagement) {
      return this.cboService.post(
        {
          route: [
            `${API_ROUTE_CBO.GET_ENG_ARTIFACTS_GRAPH_DATA.replace(
              `:projectId`,
              data?.selectedEngagement!,
            )}`,
          ],
        },
        payload,
      );
    }
    return this.cboService.post(
      {
        route: [
          `${API_ROUTE_CBO.GET_PURSUIT_ARTIFACTS_GRAPH_DATA.replace(
            `:projectId`,
            data?.selectedEngagement!,
          )}`,
        ],
      },
      payload,
    );
  }

  public getLOCGraphData(
    data: IArtifactsGraphPayload,
  ): Promise<IArtifactsLOCResponse> | IArtifactsLOCResponse {
    const isEngagement = JSON.parse(
      storage.getItem(STORAGE_CONSTANTS.isEngagement) ?? 'false',
    ) as boolean;
    const payload = { ...data };
    delete payload.selectedEngagement;
    if (isEngagement) {
      return this.cboService.post(
        {
          route: [
            `${API_ROUTE_CBO.GET_ENG_ARTIFACTS_GRAPH_DATA.replace(
              `:projectId`,
              data?.selectedEngagement!,
            )}`,
          ],
        },
        payload,
      );
    }
    return this.cboService.post(
      {
        route: [
          `${API_ROUTE_CBO.GET_PURSUIT_ARTIFACTS_GRAPH_DATA.replace(
            `:projectId`,
            data?.selectedEngagement!,
          )}`,
        ],
      },
      payload,
    );
  }

  public getCodeQualityLow(data: ICodeQualityPayload): Promise<ICodeQuality> | ICodeQuality {
    const isEngagement = JSON.parse(
      storage.getItem(STORAGE_CONSTANTS.isEngagement) ?? 'false',
    ) as boolean;

    const payload = { ...data };
    delete payload.selectedEngagement;
    if (isEngagement) {
      return this.cboService.post(
        {
          route: [
            `${API_ROUTE_CBO.POST_ENG_CODE_QUALITY.replace(`:projectId`, data?.selectedEngagement!)}`,
          ],
        },
        payload,
      );
    }
    return this.cboService.post(
      {
        route: [
          `${API_ROUTE_CBO.POST_PURSUIT_CODE_QUALITY.replace(
            `:projectId`,
            data?.selectedEngagement!,
          )}`,
        ],
      },
      payload,
    );
  }

  public getCodeQualityMedium(data: ICodeQualityPayload): Promise<ICodeQuality> | ICodeQuality {
    const isEngagement = JSON.parse(
      storage.getItem(STORAGE_CONSTANTS.isEngagement) ?? 'false',
    ) as boolean;
    const keyAssetList = JSON.parse(
      storage.getItem(STORAGE_CONSTANTS.keyAssetList) ?? '[]',
    ) as IProduct[];
    const innowakeAsset = keyAssetList?.find(
      (keyAsset) => keyAsset.product == CBOKeyAssets.truNorthCodeDiscovery,
    );
    const payload = { ...data };
    delete payload.selectedEngagement;

    if (isEngagement) {
      return this.cboService.post(
        {
          route: [
            `${API_ROUTE_CBO.POST_ENG_CODE_QUALITY.replace(`:projectId`, data?.selectedEngagement!)}`,
          ],
        },
        payload,
      );
    }
    return this.cboService.post(
      {
        route: [
          `${API_ROUTE_CBO.POST_PURSUIT_CODE_QUALITY.replace(
            `:projectId`,
            data?.selectedEngagement!,
          )}`,
        ],
      },
      payload,
    );
  }
  public getCodeQualityHigh(data: ICodeQualityPayload): Promise<ICodeQuality> | ICodeQuality {
    const isEngagement = JSON.parse(
      storage.getItem(STORAGE_CONSTANTS.isEngagement) ?? 'false',
    ) as boolean;
    const keyAssetList = JSON.parse(
      storage.getItem(STORAGE_CONSTANTS.keyAssetList) ?? '[]',
    ) as IProduct[];
    const innowakeAsset = keyAssetList?.find(
      (keyAsset) => keyAsset.product == CBOKeyAssets.truNorthCodeDiscovery,
    );

    const payload = { ...data };
    delete payload.selectedEngagement;

    if (isEngagement) {
      return this.cboService.post(
        {
          route: [
            `${API_ROUTE_CBO.POST_ENG_CODE_QUALITY.replace(`:projectId`, data?.selectedEngagement!)}`,
          ],
        },
        payload,
      );
    }
    return this.cboService.post(
      {
        route: [
          `${API_ROUTE_CBO.POST_PURSUIT_CODE_QUALITY.replace(
            `:projectId`,
            data?.selectedEngagement!,
          )}`,
        ],
      },
      payload,
    );
  }
  public getCodeQualityVeryHigh(data: ICodeQualityPayload): Promise<ICodeQuality> | ICodeQuality {
    const isEngagement = JSON.parse(
      storage.getItem(STORAGE_CONSTANTS.isEngagement) ?? 'false',
    ) as boolean;
    const payload = { ...data };
    delete payload.selectedEngagement;

    if (isEngagement) {
      return this.cboService.post(
        {
          route: [
            `${API_ROUTE_CBO.POST_ENG_CODE_QUALITY.replace(`:projectId`, data?.selectedEngagement!)}`,
          ],
        },
        payload,
      );
    }
    return this.cboService.post(
      {
        route: [
          `${API_ROUTE_CBO.POST_PURSUIT_CODE_QUALITY.replace(
            `:projectId`,
            data?.selectedEngagement!,
          )}`,
        ],
      },
      payload,
    );
  }

  //Summary metrics
  public getMetricsModule(
    data: ISummaryMetricsPayload,
  ): Promise<ISummaryMetricsResp> | ISummaryMetricsResp {
    const isEngagement = JSON.parse(
      storage.getItem(STORAGE_CONSTANTS.isEngagement) ?? 'false',
    ) as boolean;
    const payload = { ...data };
    delete payload.selectedEngagement;
    if (isEngagement) {
      return this.cboService.post(
        {
          route: [
            `${API_ROUTE_CBO.POST_ENG_CODE_QUALITY.replace(
              `:projectId`,
              data?.selectedEngagement!,
            )}`,
          ],
        },
        payload,
      );
    }
    return this.cboService.post(
      {
        route: [
          `${API_ROUTE_CBO.POST_PURSUIT_CODE_QUALITY.replace(
            `:projectId`,
            data?.selectedEngagement!,
          )}`,
        ],
      },
      payload,
    );
  }
  //End: Innowake Graph API

  //Start: TruNorth Graph API
  public getApplicationDispositioning(
    selectedEngagementId: string,
  ): Promise<IApplicationDipositioning> | IApplicationDipositioning {
    const isEngagement = JSON.parse(
      storage.getItem(STORAGE_CONSTANTS.isEngagement) ?? 'false',
    ) as boolean;
    if (isEngagement) {
      return this.cboService.get({
        route: [
          `${API_ROUTE_CBO.GET_ENG_APPLICATION_DISPOSITIONING.replace(
            `:projectId`,
            selectedEngagementId!,
          )}`,
        ],
      });
    }
    return this.cboService.get({
      route: [
        `${API_ROUTE_CBO.GET_PURSUIT_APPLICATION_DISPOSITIONING.replace(
          `:projectId`,
          selectedEngagementId!,
        )}`,
      ],
    });
  }

  public getWavePlan(selectedEngagementId: string): Promise<IWavePlan> | IWavePlan {
    const isEngagement = JSON.parse(
      storage.getItem(STORAGE_CONSTANTS.isEngagement) ?? 'false',
    ) as boolean;
    if (isEngagement) {
      return this.cboService.get({
        route: [`${API_ROUTE_CBO.GET_ENG_WAVE_PLAN.replace(`:projectId`, selectedEngagementId!)}`],
      });
    }
    return this.cboService.get({
      route: [
        `${API_ROUTE_CBO.GET_PURSUIT_WAVE_PLAN.replace(`:projectId`, selectedEngagementId!)}`,
      ],
    });
  }

  public getInventorySurveyMetrics(
    selectedEnagagementId: string,
  ): Promise<ISurveyMetrics> | ISurveyMetrics {
    const isEngagement = JSON.parse(
      storage.getItem(STORAGE_CONSTANTS.isEngagement) ?? 'false',
    ) as boolean;

    if (isEngagement) {
      return this.cboService.get({
        route: [
          `${API_ROUTE_CBO.GET_ENG_SURVEY_METRICS_INVENTORY.replace(
            `:projectId`,
            selectedEnagagementId!,
          )}`,
        ],
      });
    }
    return this.cboService.get({
      route: [
        `${API_ROUTE_CBO.GET_PURSUIT_SURVEY_METRICS_INVENTORY.replace(
          `:projectId`,
          selectedEnagagementId!,
        )}`,
      ],
    });
  }
  public getBusinessSurveyMetrics(
    selectedEnagagementId: string,
  ): Promise<ISurveyMetrics> | ISurveyMetrics {
    const isEngagement = JSON.parse(
      storage.getItem(STORAGE_CONSTANTS.isEngagement) ?? 'false',
    ) as boolean;

    if (isEngagement) {
      return this.cboService.get({
        route: [
          `${API_ROUTE_CBO.GET_ENG_SURVEY_METRICS_BUSINESS.replace(
            `:projectId`,
            selectedEnagagementId!,
          )}`,
        ],
      });
    }
    return this.cboService.get({
      route: [
        `${API_ROUTE_CBO.GET_PURSUIT_SURVEY_METRICS_BUSINESS.replace(
          `:projectId`,
          selectedEnagagementId!,
        )}`,
      ],
    });
  }
  public getDiscoveryMetrics(
    selectedEnagagementId: string,
  ): Promise<IDiscoveryMetrics> | IDiscoveryMetrics {
    const isEngagement = JSON.parse(
      storage.getItem(STORAGE_CONSTANTS.isEngagement) ?? 'false',
    ) as boolean;

    if (isEngagement) {
      return this.cboService.get({
        route: [
          `${API_ROUTE_CBO.GET_ENG_DISCOVERY_METRICS.replace(
            `:projectId`,
            selectedEnagagementId!,
          )}`,
        ],
      });
    }
    return this.cboService.get({
      route: [
        `${API_ROUTE_CBO.GET_PURSUIT_DISCOVERY_METRICS.replace(
          `:projectId`,
          selectedEnagagementId!,
        )}`,
      ],
    });
  }
  //End: TruNorth Graph API

  public postIncidentDetails(
    data: IIncidentDetailsPayload,
  ): Promise<IIncidentDetails> | IIncidentDetails {
    const isEngagement = JSON.parse(
      storage.getItem(STORAGE_CONSTANTS.isEngagement) ?? 'false',
    ) as boolean;
    if (isEngagement) {
      return this.cboService.get({
        route: [
          `${API_ROUTE_CBO.GET_ENG_OPERATE_EDGE_INCIDENT_DATA.replace(
            ':projectId',
            data?.engagementId!,
          )}`,
        ],
      });
    } else {
      return this.cboService.get({
        route: [
          `${API_ROUTE_CBO.GET_PURSUIT_OPERATE_EDGE_INCIDENT_DATA.replace(
            ':pursuitId',
            data?.engagementId!,
          )}`,
        ],
      });
    }
  }

  public postIncidentSummaryAndTrend(
    data: IOperateEndgeIncidentPayload,
  ): Promise<IIncidentDetails> | IIncidentDetails {
    const isEngagement = JSON.parse(
      storage.getItem(STORAGE_CONSTANTS.isEngagement) ?? 'false',
    ) as boolean;
    if (isEngagement) {
      return this.cboService.get({
        route: [
          `${API_ROUTE_CBO.GET_ENG_OPERATE_EDGE_ALL_GRAPHS.replace(
            ':projectId',
            data?.engagementId!,
          )}`,
        ],
      });
    } else {
      return this.cboService.get({
        route: [
          `${API_ROUTE_CBO.GET_PURSUIT_OPERATE_EDGE_ALL_GRAPHS.replace(
            ':pursuitId',
            data?.engagementId!,
          )}`,
        ],
      });
    }
  }

  public setKeyAssetConfiguration(data: IProduct) {
    const isEngagement = JSON.parse(
      storage.getItem(STORAGE_CONSTANTS.isEngagement) ?? 'false',
    ) as boolean;
    return this.cboService.patch(
      {
        route: [
          `${
            isEngagement
              ? API_ROUTE_CBO.UPDATE_KEY_ASSET_CONFIG
              : API_ROUTE_CBO.UPDATE_PURSUIT_KEY_ASSET_CONFIG
          }`,
        ],
      },
      data,
    );
  }

  public getGreenCloudData(
    payload: IGreenCloudPayload,
  ): Promise<ICarbonFootPrintResponse> | ICarbonFootPrintResponse {
    const isEngagement = JSON.parse(
      storage.getItem(STORAGE_CONSTANTS.isEngagement) ?? 'false',
    ) as boolean;
    if (isEngagement) {
      return this.cboService.get({
        route: [
          `${API_ROUTE_CBO.GET_ENG_GREEN_CLOUD_DATA.replace(':projectId', payload?.engagementId!)}`,
        ],
        query: { report: payload?.reportType },
      });
    } else {
      return this.cboService.get({
        route: [
          `${API_ROUTE_CBO.GET_PURSUIT_GREEN_CLOUD_DATA.replace(
            ':pursuitId',
            payload?.engagementId!,
          )}`,
        ],
        query: { reporttype: payload?.reportType },
      });
    }
  }

  public getLearningCenterSummaryPractitionerService(
    payload: ILearningCenterPayload,
  ): Promise<ISummaryPractitionerDataResp> | ISummaryPractitionerDataResp {
    const isEngagement = JSON.parse(
      storage.getItem(STORAGE_CONSTANTS.isEngagement) ?? 'false',
    ) as boolean;
    if (isEngagement) {
      return this.cboService.get({
        route: [
          `${API_ROUTE_CBO.GET_LEARNING_CENTER_SUMMARY_ENG.replace(
            ':engagementId',
            payload?.engagementId!,
          )}`,
        ],
      });
    } else {
      return this.cboService.get({
        route: [
          `${API_ROUTE_CBO.GET_LEARNING_CENTER_SUMMARY_PURSUIT.replace(
            ':pursuitId',
            payload?.engagementId!,
          )}`,
        ],
      });
    }
  }
  public getCoursesStatusPractitionerService(
    payload: ILearningCenterPayload,
  ): Promise<IDashboardCourseStatusResponse[]> | IDashboardCourseStatusResponse[] {
    const isEngagement = JSON.parse(
      storage.getItem(STORAGE_CONSTANTS.isEngagement) ?? 'false',
    ) as boolean;
    if (isEngagement) {
      return this.cboService.get({
        route: [
          `${API_ROUTE_CBO.GET_LEARNING_CENTER_COURSE_STATUS_ENG.replace(
            ':engagementId',
            payload?.engagementId!,
          )}`,
        ],
      });
    } else {
      return this.cboService.get({
        route: [
          `${API_ROUTE_CBO.GET_LEARNING_CENTER_COURSE_STATUS_PURSUIT.replace(
            ':pursuitId',
            payload?.engagementId!,
          )}`,
        ],
      });
    }
  }

  public getLegacyView(selectedEnagagementId: string): Promise<ILegacyDbData[]> | ILegacyDbData[] {
    const isEngagement = JSON.parse(
      storage.getItem(STORAGE_CONSTANTS.isEngagement) ?? 'false',
    ) as boolean;

    if (isEngagement) {
      return this.cboService.get({
        route: [
          `${API_ROUTE_CBO.GET_ENG_LEGACY_VIEW.replace(':projectId', selectedEnagagementId!)}`,
        ],
      });
    }
    return this.cboService.get({
      route: [
        `${API_ROUTE_CBO.GET_PURSUIT_LEGACY_VIEW.replace(':projectId', selectedEnagagementId!)}`,
      ],
    });
  }

  public getLegacyGraph(selectedEnagagementId: string): Promise<ILegacyDbData[]> | ILegacyDbData[] {
    const isEngagement = JSON.parse(
      storage.getItem(STORAGE_CONSTANTS.isEngagement) ?? 'false',
    ) as boolean;
    if (isEngagement) {
      return this.cboService.get({
        route: [
          `${API_ROUTE_CBO.GET_ENG_LEGACY_GRAPH.replace(':projectId', selectedEnagagementId!)}`,
        ],
      });
    }
    return this.cboService.get({
      route: [
        `${API_ROUTE_CBO.GET_PURSUIT_LEGACY_GRAPH.replace(':projectId', selectedEnagagementId!)}`,
      ],
    });
  }

  public getWorkflowListService(
    payload: IWorkflowListPayload,
  ): Promise<IWorkflowResponse> | IWorkflowResponse {
    const isEngagement = JSON.parse(
      storage.getItem(STORAGE_CONSTANTS.isEngagement) ?? 'false',
    ) as boolean;

    if (isEngagement) {
      return this.cboService.get({
        route: [
          `${API_ROUTE_CBO.GET_ENG_WORKFLOWS_LIST.replace(':projectId', payload?.selectedEngagementId)}`,
        ],
        query: {
          page: payload.page,
          size: payload.size,
          search: payload.search,
          dtql: payload.dtql,
        },
      });
    }
    return this.cboService.get({
      route: [
        `${API_ROUTE_CBO.GET_PURSUIT_WORKFLOWS_LIST.replace(':projectId', payload?.selectedEngagementId)}`,
      ],
      query: { page: payload.page, size: payload.size, search: payload.search, dtql: payload.dtql },
    });
  }

  public getWorkflowMetricsService(
    payload: IWorkflowPayload,
  ): Promise<IExecutionMetricDetailsResponse> | IExecutionMetricDetailsResponse {
    const isEngagement = JSON.parse(
      storage.getItem(STORAGE_CONSTANTS.isEngagement) ?? 'false',
    ) as boolean;
    if (isEngagement) {
      return this.cboService.get({
        route: [
          `${API_ROUTE_CBO.GET_ENG_WORKFLOW_METRICS.replace(':projectId', payload?.selectedEngagementId).replace(':workflowId', payload?.workflowId!)}`,
        ],
      });
    }
    return this.cboService.get({
      route: [
        `${API_ROUTE_CBO.GET_PURSUIT_WORKFLOW_METRICS.replace(':projectId', payload?.selectedEngagementId!).replace(':workflowId', payload?.workflowId!)}`,
      ],
    });
  }

  public getWorkflowReportsService(
    payload: IWorkflowPayload,
  ): Promise<IWorkflowReportResponse> | IWorkflowReportResponse {
    const isEngagement = JSON.parse(
      storage.getItem(STORAGE_CONSTANTS.isEngagement) ?? 'false',
    ) as boolean;
    if (isEngagement) {
      return this.cboService.get({
        route: [
          `${API_ROUTE_CBO.GET_ENG_WORKFLOW_REPORTS.replace(':projectId', payload?.selectedEngagementId).replace(':workflowId', payload?.workflowId)}`,
        ],
      });
    }
    return this.cboService.get({
      route: [
        `${API_ROUTE_CBO.GET_PURSUIT_WORKFLOW_REPORTS.replace(':projectId', payload?.selectedEngagementId).replace(':workflowId', payload?.workflowId)}`,
      ],
    });
  }

  public getDEPScanAlertService(
    payload: IDEPAlertScanPayload,
  ): Promise<IDEPAlertScanResponse> | IDEPAlertScanResponse {
    const isEngagement = JSON.parse(
      storage.getItem(STORAGE_CONSTANTS.isEngagement) ?? 'false',
    ) as boolean;
    if (isEngagement) {
      return this.cboService.get({
        route: [`${API_ROUTE_CBO.GET_ENG_DEP_.replace(':projectId', payload?.engagementId!)}`],
        query: { repo: payload?.repoName },
      });
    } else {
      return this.cboService.get({
        route: [`${API_ROUTE_CBO.GET_PURSUIT_DEP_.replace(':pursuitId', payload?.engagementId!)}`],
        query: { repo: payload?.repoName },
      });
    }
  }

  public getDEPCodeSpacesService(
    payload: string,
  ): Promise<ICodespacesResponse[]> | ICodespacesResponse[] {
    const isEngagement = JSON.parse(
      storage.getItem(STORAGE_CONSTANTS.isEngagement) ?? 'false',
    ) as boolean;
    const userName = storage.getItem(STORAGE_CONSTANTS.userName) ?? '';
    const updatedUserName = userName?.replace('@', '_').replace('.com', '');

    if (isEngagement) {
      return this.cboService.get({
        route: [`${API_ROUTE_CBO.GET_ENG_DEP_CODESPACES.replace(':projectId', payload!)}`],
        query: { userId: updatedUserName },
      });
    } else {
      return this.cboService.get({
        route: [`${API_ROUTE_CBO.GET_PURSUIT_DEP_CODESPACES.replace(':pursuitId', payload!)}`],
        query: { userId: updatedUserName },
      });
    }
  }

  public getProblemInsightsRunListService(
    payload: string,
  ): Promise<IProblemInsightsAssignmentGroupData[]> | IProblemInsightsAssignmentGroupData[] {
    const isEngagement = JSON.parse(
      storage.getItem(STORAGE_CONSTANTS.isEngagement) ?? 'false',
    ) as boolean;
    if (isEngagement) {
      return this.cboService.get({
        route: [
          `${API_ROUTE_CBO.GET_ENG_PROBLEM_INSIGHTS_RUN_LIST.replace(':projectId', payload!)}`,
        ],
      });
    } else {
      return this.cboService.get({
        route: [
          `${API_ROUTE_CBO.GET_PURSUIT_PROBLEM_INSIGHTS_RUN_LIST.replace(
            ':engagementId',
            payload!,
          )}`,
        ],
      });
    }
  }

  public getProblemInsightsRunDetailsService(
    payload: IProblemInsightsPayload,
  ): Promise<IProblemInsightsRunDetailsData> | IProblemInsightsRunDetailsData {
    const isEngagement = JSON.parse(
      storage.getItem(STORAGE_CONSTANTS.isEngagement) ?? 'false',
    ) as boolean;
    if (isEngagement) {
      return this.cboService.get({
        route: [
          `${API_ROUTE_CBO.GET_ENG_PROBLEM_INSIGHTS_RUN_DETAILS.replace(
            ':projectId',
            payload?.engagementId!,
          )}`,
        ],
        query: { runid: payload?.runId, assignmentgroup: payload?.assignmentGroup },
      });
    } else {
      return this.cboService.get({
        route: [
          `${API_ROUTE_CBO.GET_PURSUIT_PROBLEM_INSIGHTS_RUN_DETAILS.replace(
            ':engagementId',
            payload?.engagementId!,
          )}`,
        ],
        query: { runid: payload?.runId, assignmentgroup: payload?.assignmentGroup },
      });
    }
  }

  public getproblemgroupoverviewService(
    payload: IProblemInsightsPayload,
  ): Promise<IProblemGroupOverviewResponse> | IProblemGroupOverviewResponse {
    const isEngagement = JSON.parse(
      storage.getItem(STORAGE_CONSTANTS.isEngagement) ?? 'false',
    ) as boolean;

    if (isEngagement) {
      return this.cboService.get({
        route: [
          `${API_ROUTE_CBO.GET_ENG_PROBLEM_GROUP_OVERVIEW.replace(
            ':projectId',
            payload?.engagementId!,
          )}`,
        ],
        query: {
          runid: payload?.runId,
          from: payload?.from ?? '',
          to: payload?.to,
          assignmentgroup: payload?.assignmentGroup,
        },
      });
    } else {
      return this.cboService.get({
        route: [
          `${API_ROUTE_CBO.GET_PURSUIT_PROBLEM_GROUP_OVERVIEW.replace(
            ':engagementId',
            payload?.engagementId!,
          )}`,
        ],
        query: {
          runid: payload?.runId,
          from: payload?.from ?? '',
          to: payload?.to,
          assignmentgroup: payload?.assignmentGroup,
        },
      });
    }
  }

  public kmsApplicationsService(): Promise<IKMSResponse[]> | IKMSResponse[] {
    return this.cboService.get({
      route: [`${API_ROUTE_CBO.GET_KMS_APPLICATIONS}`],
    });
  }
  public getIssueSummaryService(
    payload: string,
  ): Promise<IIssueSummaryResponse[]> | IIssueSummaryResponse[] {
    const isEngagement = JSON.parse(
      storage.getItem(STORAGE_CONSTANTS.isEngagement) ?? 'false',
    ) as boolean;
    if (isEngagement) {
      return this.cboService.get({
        route: [`${API_ROUTE_CBO.GET_ENG_RISK_SUMMARY.replace(':engagementId', payload!)}`],
        query: { type: 'issue' },
      });
    } else {
      return this.cboService.get({
        route: [`${API_ROUTE_CBO.GET_RISK_SUMMARY.replace(':pursuitId', payload!)}`],
        query: { type: 'issue' },
      });
    }
  }

  public getRiskSummaryService(
    payload: string,
  ): Promise<IRiskSummaryResponse[]> | IRiskSummaryResponse[] {
    const isEngagement = JSON.parse(
      storage.getItem(STORAGE_CONSTANTS.isEngagement) ?? 'false',
    ) as boolean;
    if (isEngagement) {
      return this.cboService.get({
        route: [`${API_ROUTE_CBO.GET_ENG_RISK_SUMMARY.replace(':engagementId', payload!)}`],
        query: { type: 'risk' },
      });
    } else {
      return this.cboService.get({
        route: [`${API_ROUTE_CBO.GET_RISK_SUMMARY.replace(':pursuitId', payload!)}`],
        query: { type: 'risk' },
      });
    }
  }

  public getActiveSummaryService(
    payload: string,
  ): Promise<IActiveSummaryResponse> | IActiveSummaryResponse {
    const isEngagement = JSON.parse(
      storage.getItem(STORAGE_CONSTANTS.isEngagement) ?? 'false',
    ) as boolean;
    if (isEngagement) {
      return this.cboService.get({
        route: [`${API_ROUTE_CBO.GET_ENG_ACTIVITY_SUMMARY.replace(':engagementId', payload!)}`],
      });
    } else {
      return this.cboService.get({
        route: [`${API_ROUTE_CBO.GET_ACTIVITY_SUMMARY.replace(':pursuitId', payload!)}`],
      });
    }
  }

  public getTransitionService(
    payload: string,
  ): Promise<ITransitionTableResponseData[]> | ITransitionTableResponseData[] {
    const isEngagement = JSON.parse(
      storage.getItem(STORAGE_CONSTANTS.isEngagement) ?? 'false',
    ) as boolean;
    if (isEngagement) {
      return this.cboService.get({
        route: [`${API_ROUTE_CBO.GET_ENG_TRANSITIONS.replace(':engagementId', payload!)}`],
      });
    } else {
      return this.cboService.get({
        route: [`${API_ROUTE_CBO.GET_TRANSITIONS.replace(':pursuitId', payload!)}`],
      });
    }
  }

  //CBO-2901_AI_AUTO_NEW_CODE : Added new Services for AppProgressionData, ServerProgressionData and ArchetypeList

  public getAppsProgressionService(
    payload: IMigrationPayload,
  ): Promise<IAppsProgressionResponse[]> | IAppsProgressionResponse[] {
    const isEngagement = JSON.parse(
      storage.getItem(STORAGE_CONSTANTS.isEngagement) ?? 'false',
    ) as boolean;
    if (isEngagement) {
      return this.cboService.get({
        route: [
          `${API_ROUTE_CBO.GET_ENG_APP_PROGRESSION.replace(
            ':engagementId',
            payload.engagementId!,
          )}`,
        ],
        query: { architype: encodeURIComponent(payload?.archetype!) },
      });
    } else {
      return this.cboService.get({
        route: [
          `${API_ROUTE_CBO.GET_APP_PROGRESSION.replace(':pursuitId', payload.engagementId!)}`,
        ],
        query: { architype: encodeURIComponent(payload?.archetype!) },
      });
    }
  }
  public getServersProgressionService(
    payload: IMigrationPayload,
  ): Promise<IServersProgressionResponse[]> | IServersProgressionResponse[] {
    const isEngagement = JSON.parse(
      storage.getItem(STORAGE_CONSTANTS.isEngagement) ?? 'false',
    ) as boolean;
    if (isEngagement) {
      return this.cboService.get({
        route: [
          `${API_ROUTE_CBO.GET_ENG_SERVER_PROGRESSION.replace(
            ':engagementId',
            payload.engagementId!,
          )}`,
        ],
        query: { architype: encodeURIComponent(payload?.archetype!) },
      });
    } else {
      return this.cboService.get({
        route: [
          `${API_ROUTE_CBO.GET_SERVER_PROGRESSION.replace(':pursuitId', payload.engagementId!)}`,
        ],
        query: { architype: encodeURIComponent(payload?.archetype!) },
      });
    }
  }

  public getArchetypeListService(payload: IMigrationPayload): Promise<string[]> | string[] {
    const isEngagement = JSON.parse(
      storage.getItem(STORAGE_CONSTANTS.isEngagement) ?? 'false',
    ) as boolean;
    if (isEngagement) {
      return this.cboService.get({
        route: [
          `${API_ROUTE_CBO.GET_ENG_ARCHETYPE_LIST.replace(
            ':engagementId',
            payload?.engagementId!,
          )}`,
        ],
      });
    } else {
      return this.cboService.get({
        route: [
          `${API_ROUTE_CBO.GET_ARCHETYPE_LIST.replace(':pursuitId', payload?.engagementId!)}`,
        ],
      });
    }
  }

  //CBO-2901_AI_AUTO_NEW_CODE: generated the services for Monthly Apps Progression Data
  public getAppsProgressionMonthlyService(
    payload: IMigrationPayload,
  ): Promise<IAppsProgressionMonthlyResponse> | IAppsProgressionMonthlyResponse {
    const isEngagement = JSON.parse(
      storage.getItem(STORAGE_CONSTANTS.isEngagement) ?? 'false',
    ) as boolean;
    if (isEngagement) {
      return this.cboService.get({
        route: [
          `${API_ROUTE_CBO.GET_ENG_APP_PROGRESSION_MONTHLY.replace(
            ':engagementId',
            payload?.engagementId!,
          )}`,
        ],
        query: { architype: encodeURIComponent(payload?.archetype!) },
      });
    }
    return this.cboService.get({
      route: [
        `${API_ROUTE_CBO.GET_APP_PROGRESSION_MONTHLY.replace(
          ':pursuitId',
          payload?.engagementId!,
        )}`,
      ],
      query: { architype: encodeURIComponent(payload?.archetype!) },
    });
  }
  //CBO-2901_AI_AUTO_NEW_CODE: generated the services for Monthly Servers Progression Data

  public getServersProgressionMonthlyService(
    payload: IMigrationPayload,
  ): Promise<IServersProgressionMonthlyResponse> | IServersProgressionMonthlyResponse {
    const isEngagement = JSON.parse(
      storage.getItem(STORAGE_CONSTANTS.isEngagement) ?? 'false',
    ) as boolean;
    if (isEngagement) {
      return this.cboService.get({
        route: [
          `${API_ROUTE_CBO.GET_ENG_SERVER_PROGRESSION_MONTHLY.replace(
            ':engagementId',
            payload?.engagementId!,
          )}`,
        ],
        query: { architype: encodeURIComponent(payload?.archetype!) },
      });
    }
    return this.cboService.get({
      route: [
        `${API_ROUTE_CBO.GET_SERVER_PROGRESSION_MONTHLY.replace(
          ':pursuitId',
          payload?.engagementId!,
        )}`,
      ],
      query: { architype: encodeURIComponent(payload?.archetype!) },
    });
  }

  public getBlockersandCausesService(
    payload: IMigrationPayload,
  ): Promise<IBlockersAndCausesResponse> | IBlockersAndCausesResponse {
    const isEngagement = JSON.parse(
      storage.getItem(STORAGE_CONSTANTS.isEngagement) ?? 'false',
    ) as boolean;
    if (isEngagement) {
      return this.cboService.get({
        route: [
          `${API_ROUTE_CBO.GET_ENG_BLOCKER_CAUSES.replace(':engagementId', payload.engagementId!)}`,
        ],
        query: { architype: encodeURIComponent(payload?.archetype!) },
      });
    } else {
      return this.cboService.get({
        route: [`${API_ROUTE_CBO.GET_BLOCKER_CAUSES.replace(':pursuitId', payload.engagementId!)}`],
        query: { architype: encodeURIComponent(payload?.archetype!) },
      });
    }
  }
  // CB360 starts here//
  public getTcoInsightsService(
    selectedEnagagementId: string,
  ): Promise<ITCOInsightsResponse> | ITCOInsightsResponse {
    const isEngagement = JSON.parse(
      storage.getItem(STORAGE_CONSTANTS.isEngagement) ?? 'false',
    ) as boolean;
    if (isEngagement) {
      return this.cboService.get({
        route: [
          `${API_ROUTE_CBO.GET_ENG_TCO_INSIGHTS.replace(':engagementId', selectedEnagagementId)}`,
        ],
      });
    }
    return this.cboService.get({
      route: [`${API_ROUTE_CBO.GET_TCO_INSIGHTS.replace(':engagementId', selectedEnagagementId)}`],
    });
  }

  public getCostTrendsService(
    selectedEnagagementId: string,
  ): Promise<ICostTrendResponse[]> | ICostTrendResponse[] {
    const isEngagement = JSON.parse(
      storage.getItem(STORAGE_CONSTANTS.isEngagement) ?? 'false',
    ) as boolean;
    if (isEngagement) {
      return this.cboService.get({
        route: [
          `${API_ROUTE_CBO.GET_ENG_COST_TREND.replace(':engagementId', selectedEnagagementId)}`,
        ],
      });
    }
    return this.cboService.get({
      route: [`${API_ROUTE_CBO.GET__COST_TREND.replace(':engagementId', selectedEnagagementId)}`],
    });
  }

  public getCostAllocationSummaryService(
    selectedEnagagementId: string,
  ): Promise<ICostAllocationSummaryResponse> | ICostAllocationSummaryResponse {
    const isEngagement = JSON.parse(
      storage.getItem(STORAGE_CONSTANTS.isEngagement) ?? 'false',
    ) as boolean;
    if (isEngagement) {
      return this.cboService.get({
        route: [
          `${API_ROUTE_CBO.GET_ENG_COST_ALLOCATION_SUMMARY.replace(
            ':engagementId',
            selectedEnagagementId,
          )}`,
        ],
      });
    }
    return this.cboService.get({
      route: [
        `${API_ROUTE_CBO.GET_COST_ALLOCATION_SUMMARY.replace(
          ':engagementId',
          selectedEnagagementId,
        )}`,
      ],
    });
  }

  public getAllocationTrendService(
    selectedEnagagementId: string,
  ): Promise<IAllocationTrendResponse> | IAllocationTrendResponse {
    const isEngagement = JSON.parse(
      storage.getItem(STORAGE_CONSTANTS.isEngagement) ?? 'false',
    ) as boolean;
    if (isEngagement) {
      return this.cboService.get({
        route: [
          `${API_ROUTE_CBO.GET_ENG_ALLOCATION_TREND.replace(
            ':engagementId',
            selectedEnagagementId,
          )}`,
        ],
      });
    }
    return this.cboService.get({
      route: [
        `${API_ROUTE_CBO.GET_ALLOCATION_TREND.replace(':engagementId', selectedEnagagementId)}`,
      ],
    });
  }

  public getCloudCostSummaryCostTrendService(
    selectedEnagagementId: string,
  ): Promise<ICloudCostSummaryCostTrendResponse[]> | ICloudCostSummaryCostTrendResponse[] {
    const isEngagement = JSON.parse(
      storage.getItem(STORAGE_CONSTANTS.isEngagement) ?? 'false',
    ) as boolean;
    if (isEngagement) {
      return this.cboService.get({
        route: [
          `${API_ROUTE_CBO.GET_ENG_CLOUD_COST_SUMMARY_TREND.replace(
            ':engagementId',
            selectedEnagagementId,
          )}`,
        ],
      });
    }
    return this.cboService.get({
      route: [
        `${API_ROUTE_CBO.GET_CLOUD_COST_SUMMARY_TREND.replace(
          ':engagementId',
          selectedEnagagementId,
        )}`,
      ],
    });
  }
  //CB360 Ends here

  // CBO-3061_AI_AUTO_ENHANCE_CODE:Created service for ServersByUpgrade

  public getServersByUpgradeService(
    payload: string,
  ): Promise<IServersByUpgradeResponse[]> | IServersByUpgradeResponse[] {
    const isEngagement = JSON.parse(
      storage.getItem(STORAGE_CONSTANTS.isEngagement) ?? 'false',
    ) as boolean;
    if (isEngagement) {
      return this.cboService.get({
        route: [`${API_ROUTE_CBO.GET_ENG_SERVERS_BY_UPGRADE.replace(':engagementId', payload!)}`],
      });
    } else {
      return this.cboService.get({
        route: [`${API_ROUTE_CBO.GET_SERVERS_BY_UPGRADE.replace(':engagementId', payload!)}`],
      });
    }
  }

  public getDispositionByWaveService(
    payload: string,
  ): Promise<IDispositionsByWaveData> | IDispositionsByWaveData {
    const isEngagement = JSON.parse(
      storage.getItem(STORAGE_CONSTANTS.isEngagement) ?? 'false',
    ) as boolean;
    if (isEngagement) {
      return this.cboService.get({
        route: [`${API_ROUTE_CBO.GET_ENG_DISPOSITIONS_BY_WAVE.replace(':engagementId', payload!)}`],
      });
    } else {
      return this.cboService.get({
        route: [`${API_ROUTE_CBO.GET_DISPOSITIONS_BY_WAVE.replace(':pursuitId', payload!)}`],
      });
    }
  }

  //CBO-4166_AI_AUTO_ENHANCE_CODE create following services getDefectAgeingService, getNumberOfTestCasesService, getSeverityVsCreatedOnService, getTableCountService and getTestRunStatusServic for both engagement and pursuit

  public getDefectAgeingService(
    payload: string,
  ): Promise<IDefectAgeingResponse> | IDefectAgeingResponse {
    const isEngagement = JSON.parse(
      storage.getItem(STORAGE_CONSTANTS.isEngagement) ?? 'false',
    ) as boolean;
    if (isEngagement) {
      return this.cboService.get({
        route: [`${API_ROUTE_CBO.GET_ENG_DEFECT_AGEING.replace(':projectId', payload!)}`],
      });
    } else {
      return this.cboService.get({
        route: [`${API_ROUTE_CBO.GET_DEFECT_AGEING.replace(':projectId', payload!)}`],
      });
    }
  }

  public getNumberOfTestCasesService(
    payload: string,
  ): Promise<INumberOfTestCasesResponse> | INumberOfTestCasesResponse {
    const isEngagement = JSON.parse(
      storage.getItem(STORAGE_CONSTANTS.isEngagement) ?? 'false',
    ) as boolean;
    if (isEngagement) {
      return this.cboService.get({
        route: [`${API_ROUTE_CBO.GET_ENG_NUMBER_OF_TEST_CASES.replace(':projectId', payload!)}`],
      });
    } else {
      return this.cboService.get({
        route: [`${API_ROUTE_CBO.GET_NUMBER_OF_TEST_CASES.replace(':projectId', payload!)}`],
      });
    }
  }

  public getSeverityVsCreatedOnService(
    payload: string,
  ): Promise<ISeverityVsCreatedOnResponse> | ISeverityVsCreatedOnResponse {
    const isEngagement = JSON.parse(
      storage.getItem(STORAGE_CONSTANTS.isEngagement) ?? 'false',
    ) as boolean;
    if (isEngagement) {
      return this.cboService.get({
        route: [`${API_ROUTE_CBO.GET_ENG_SEVERITY_VS_CREATED_ON.replace(':projectId', payload!)}`],
      });
    } else {
      return this.cboService.get({
        route: [`${API_ROUTE_CBO.GET_SEVERITY_VS_CREATED_ON.replace(':projectId', payload!)}`],
      });
    }
  }

  public getTableCountService(payload: string): Promise<ITableCountResponse> | ITableCountResponse {
    const isEngagement = JSON.parse(
      storage.getItem(STORAGE_CONSTANTS.isEngagement) ?? 'false',
    ) as boolean;
    if (isEngagement) {
      return this.cboService.get({
        route: [`${API_ROUTE_CBO.GET_ENG_TABLE_COUNT.replace(':projectId', payload!)}`],
      });
    } else {
      return this.cboService.get({
        route: [`${API_ROUTE_CBO.GET_TABLE_COUNT.replace(':projectId', payload!)}`],
      });
    }
  }

  public getTestRunStatusService(
    payload: string,
  ): Promise<ITestRunStatusResponse> | ITestRunStatusResponse {
    const isEngagement = JSON.parse(
      storage.getItem(STORAGE_CONSTANTS.isEngagement) ?? 'false',
    ) as boolean;
    if (isEngagement) {
      return this.cboService.get({
        route: [`${API_ROUTE_CBO.GET_ENG_TEST_RUN_STATUS.replace(':projectId', payload!)}`],
      });
    } else {
      return this.cboService.get({
        route: [`${API_ROUTE_CBO.GET_TEST_RUN_STATUS.replace(':projectId', payload!)}`],
      });
    }
  }

  public getTaxonomyEditorService(
    data: string,
  ): Promise<ITaxonomyEditorData[]> | ITaxonomyEditorData[] {
    const isEngagement = JSON.parse(storage.getItem(STORAGE_CONSTANTS.isEngagement) ?? 'false');
    if (isEngagement) {
      return this.cboService.get({
        route: [`${API_ROUTE_CBO.GET_ENG_TAXONOMY_EDITOR.replace(':engagementId', data)}`],
      });
    }
    return this.cboService.get({
      route: [`${API_ROUTE_CBO.GET_PURSUIT_TAXONOMY_EDITOR.replace(':pursuitId', data)}`],
    });
  }
}

const EngagementSummaryGraphService = new EngagementGraphService();
export default EngagementSummaryGraphService;
