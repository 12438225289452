import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Modal, Typography } from 'antd';
import { STORAGE_CONSTANTS, useStorage, storage } from '../../../libs/services/LocalStorage';
import { routes } from '../../../navigation/Routes';
import { getAssessAuthUrl } from '../../CBOLogin/CBOLoginConstants';
import {
  AssetAuthorisationtLabels as LABELS,
  POPUP_WINDOW_CONFIG,
} from '../../AssetAuthorisation/AssetAuthorisationConstants';
import './TruNorthDashboard.less';
import Loader from '../../../components/Loader/Loader';
import AxiosInstance from '../../../libs/services/AxiosInstance';
import { useAppSelector, IApplicationState } from '../../../libs/store/reducers';
import { formatUrl, handleZoom } from '../../../libs/helper/HelperFunctions';
import { useDispatch } from 'react-redux';
import {
  IVisitedPagePayload,
  SectionType,
  TrafficAnalyticsActions,
} from '../../../libs/store/actions/WebAnalytics/TrafficAnalyticsActions';
import { truNorthModernizationCentralDemoInstanceConfig } from '../KeyAssetsConstant';
import { IProduct } from '../../../libs/models/cbo/EngagementModels';
import { API_ROUTE_CBO } from '../../../libs/services/ApiRoute';

interface IAssetProps {
  url?: string;
  selectedAssetConfig?: IProduct;
  isEngagement?: boolean;
  selectedEnagagementName?: string;
  selectedEnagagementId?: string;
  selectedClientName?: string;
}
const TruNorthDashboard = ({
  url,
  selectedAssetConfig,
  isEngagement,
  selectedEnagagementName,
  selectedEnagagementId,
  selectedClientName,
}: IAssetProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showAssess, setshowAssess] = useState<boolean>(false);
  const [isReachable, setIsReachable] = useState<boolean>(true);
  const instance = AxiosInstance.create();
  const { selectedPhase } = useAppSelector((state: IApplicationState) => ({
    selectedPhase: state.cboViewSummary.selectedPhase,
  }));

  const handleAssessConfig = () => {
    const authUrl = getAssessAuthUrl(
      selectedAssetConfig?.configDone
        ? formatUrl(selectedAssetConfig?.authDomainUrl ?? '')
        : truNorthModernizationCentralDemoInstanceConfig.authDomainUrl,
      selectedAssetConfig?.configDone
        ? selectedAssetConfig?.realm
        : truNorthModernizationCentralDemoInstanceConfig.realm,
    );
    const path =
      window.location?.pathname == routes.cboEngagementSummary
        ? `${window.location?.pathname}#${selectedPhase}`
        : window.location?.pathname;
    storage.setItem(STORAGE_CONSTANTS.authOriginatorUrl, path);
    window.location.href = authUrl;
  };

  const dispatcher = {
    postPageVisited: (data: IVisitedPagePayload) =>
      dispatch(TrafficAnalyticsActions.postVisitedPageStart(data)),
  };

  useEffect(() => {
    dispatcher.postPageVisited({
      sectionType: isEngagement ? SectionType.ENG_ASSET : SectionType.PURSUIT_ASSET,
      assetName: SectionType.TRUNORTH_MODERNIZATION_CENTRAL,
      name: selectedEnagagementName,
      id: selectedEnagagementId,
      clientName: selectedClientName,
    });
    instance
      .get(
        isEngagement
          ? formatUrl(selectedAssetConfig?.healthCheckUrl!)
          : selectedAssetConfig?.configDone
            ? formatUrl(selectedAssetConfig?.healthCheckUrl!)
            : formatUrl(truNorthModernizationCentralDemoInstanceConfig.healthCheckUrl),
      )
      .then(function (response) {
        console.log('Health check Response', response);

        setIsReachable(true);
        const tokenExpiry: any = selectedAssetConfig?.configDone
          ? storage.getItemSession(STORAGE_CONSTANTS.assessConfigTokenExpiry)
          : storage.getItemSession(STORAGE_CONSTANTS.assessTokenExpiry);
        const assessToken: any = selectedAssetConfig?.configDone
          ? storage.getItemSession(STORAGE_CONSTANTS.assessConfigAccessToken)
          : storage.getItemSession(STORAGE_CONSTANTS.assessAccessToken);
        if (assessToken) {
          setshowAssess(true); // if active session token is there show portlet
        } else {
          setshowAssess(false);
          handleAssessConfig();
        }
        // if (assessToken || tokenExpiry) {
        //   if (Math.round(Date.now() / 1000) > parseInt(tokenExpiry || '')) {
        //     setshowAssess(false);
        //     handleAssessConfig();
        //   } else {
        //     setshowAssess(true); //if token valid show portlet
        //   }
        // } else if (!assessToken || !tokenExpiry) {
        //   setshowAssess(false);
        //   handleAssessConfig();
        // }
      })
      .catch(function (error) {
        console.log('Error', error);
        setIsReachable(false);
      });
    return () => {
      if (selectedAssetConfig?.configDone) {
        storage?.removeSessionItem(STORAGE_CONSTANTS.assessConfigTokenExpiry);
        storage?.removeSessionItem(STORAGE_CONSTANTS.assessConfigAccessToken);
      } else {
        storage?.removeSessionItem(STORAGE_CONSTANTS.assessTokenExpiry);
        storage?.removeSessionItem(STORAGE_CONSTANTS.assessAccessToken);
      }

      dispatcher.postPageVisited({
        sectionType: isEngagement ? SectionType.ENG_ASSET : SectionType.PURSUIT_ASSET,
        assetName: SectionType.TRUNORTH_MODERNIZATION_CENTRAL,
        id: selectedEnagagementId,
        name: selectedEnagagementName,
        clientName: selectedClientName,
      });
    };
  }, []);

  const getIframeUrl = (instanceUrl?: string, portfolioId?: string) => {
    return `${formatUrl(instanceUrl ?? '')}/portfolios/${portfolioId}/portfolio`;
  };

  const loadIframe = () => {
    return (
      <Loader loaderName="Authorizing..." loading={!showAssess}>
        <iframe
          id="assess"
          className="assess-iframe"
          title="Assess"
          src={
            url
              ? url
              : isEngagement
                ? getIframeUrl(selectedAssetConfig?.instanceUrl, selectedAssetConfig?.portfolioId)
                : selectedAssetConfig?.configDone
                  ? getIframeUrl(selectedAssetConfig?.instanceUrl, selectedAssetConfig?.portfolioId)
                  : getIframeUrl(
                      truNorthModernizationCentralDemoInstanceConfig.demoInstanceUrl,
                      truNorthModernizationCentralDemoInstanceConfig.portfolioid,
                    )
          }
          onLoad={() => handleZoom('assess-iframe')}
        />
      </Loader>
    );
  };

  return (
    <div
      className={
        isReachable
          ? 'trunorth-dashboard-wrapper'
          : 'trunorth-dashboard-wrapper service-unreachable'
      }
    >
      {isReachable ? (
        loadIframe()
      ) : (
        <>
          <img
            className="service-unreachable-image"
            src={`${process.env.PUBLIC_URL}/config-icons/cbo/asset-authorize/service-unreachable.svg`}
            alt="unreachable"
          />
          <Typography style={{ fontSize: '1.5rem', fontWeight: '500' }}>
            Please connect to VPN and try again
          </Typography>
        </>
      )}
    </div>
  );
};

export default TruNorthDashboard;
