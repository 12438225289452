import React, { useState, useEffect } from 'react';
import Loader from '../../../components/Loader/Loader';
import '../TruNorthDashboard/TruNorthDashboard.less';
import {
  AssetAuthorisationtLabels as LABELS,
  POPUP_WINDOW_CONFIG,
} from '../../AssetAuthorisation/AssetAuthorisationConstants';
import AxiosInstance from '../../../libs/services/AxiosInstance';
import { useHistory } from 'react-router';
import { routes } from '../../../navigation/Routes';
import { handleZoom } from '../../../libs/helper/HelperFunctions';
import { useDispatch } from 'react-redux';
import {
  IVisitedPagePayload,
  SectionType,
  TrafficAnalyticsActions,
} from '../../../libs/store/actions/WebAnalytics/TrafficAnalyticsActions';
import { IProduct } from '../../../libs/models/cbo/EngagementModels';
import { talkQEDemoInstanceConfig } from '../KeyAssetsConstant';

interface IAssetProps {
  selectedAssetConfig?: IProduct;
  isEngagement?: boolean;
  selectedEnagagementName?: string;
  selectedEnagagementId?: string;
  selectedClientName?: string;
}

const TalkQEDashboard = ({
  isEngagement,
  selectedAssetConfig,
  selectedEnagagementName,
  selectedEnagagementId,
  selectedClientName,
}: IAssetProps) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const instance = AxiosInstance.create();
  const [url, seturl] = useState('');

  const dispatcher = {
    postPageVisited: (data: IVisitedPagePayload) =>
      dispatch(TrafficAnalyticsActions.postVisitedPageStart(data)),
  };

  useEffect(() => {
    const instanceUrl = isEngagement
      ? selectedAssetConfig?.instanceUrl
      : talkQEDemoInstanceConfig.demoInstanceUrl;
    dispatcher.postPageVisited({
      sectionType: isEngagement ? SectionType.ENG_ASSET : SectionType.PURSUIT_ASSET,
      assetName: SectionType.TALKQE,
      name: selectedEnagagementName,
      id: selectedEnagagementId,
      clientName: selectedClientName,
    });
    instance
      .get(`${instanceUrl}/auth/sso`)
      .then((response) => {
        let authorisationWindow: any;
        authorisationWindow = window.open(
          response.data,
          LABELS.assessPopupWindowName,
          POPUP_WINDOW_CONFIG,
        );

        window.addEventListener('message', (event: MessageEvent) => {
          if (event.origin === instanceUrl) {
            if (event.data === `${instanceUrl}/talkqe/#/?ssoLoginSuccess=true`) {
              seturl(event.data);
              authorisationWindow?.close();
              setIsLoading(false);
            } else if (event.data === `${instanceUrl}/talkqe/#/forbidden`) {
              console.log('Forbidden');
              authorisationWindow?.close();
              setIsLoading(false);
              history.push(routes.cboDashboard);
            }
          }
        });
      })
      .catch(function (error) {
        console.log(error);
      });
    return () => {
      dispatcher.postPageVisited({
        sectionType: isEngagement ? SectionType.ENG_ASSET : SectionType.PURSUIT_ASSET,
        assetName: SectionType.TALKQE,
        name: selectedEnagagementName,
        id: selectedEnagagementId,
        clientName: selectedClientName,
      });
    };
  }, []);

  useEffect(() => {
    url && handleZoom('assess-iframe');
  }, [url]);

  return (
    <div className="trunorth-dashboard-wrapper">
      {url && (
        <Loader loaderName="Authorizing..." loading={isLoading}>
          <iframe
            id="assess"
            className="assess-iframe"
            title="Assess"
            src={url}
            onLoad={() => setIsLoading(false)}
          />
        </Loader>
      )}
    </div>
  );
};
export default TalkQEDashboard;
