export enum LogLevel {
  None,
  Errors,
  Debug,
  Trace,
}
import { ONECBO, CWB, DCA } from './AppConstants';

const DCAurls: { [key: string]: string } = {
  local: 'https://jmly57rgw1.execute-api.us-east-1.amazonaws.com',
  dev: 'https://tangradcaapi.deloitte.com',
};
const CBOurls: { [key: string]: string } = {
  stack_url: ONECBO.STACK_URL,
  localStack: ONECBO.STACK_URL,
};

const CWBurls = {
  stack_url: CWB.STACK_URL,
  // staging: 'https://cloudworkbenchapi.deloitte.com',
  // prod: 'https://cloudworkbenchapi.deloitte.com',
};

const DCPurls = 'https://3cm6q63da8.execute-api.us-east-1.amazonaws.com';

// Using this as baseURL for migrated Tangra APIs. To be changed later to use as baseURL for all APIs of TangraBE
const tangraURLs: { [key: string]: string } = {
  stack_url: DCA.STACK_URL,
  // prod: 'https://qsp5weyu2a.execute-api.us-east-1.amazonaws.com/V1',
  // dev: 'https://jfsjlgmg74.execute-api.us-east-1.amazonaws.com/V1',
};

const SocketURL: { [key: string]: string } = {
  prod: 'wss://0zl6fu078i.execute-api.us-east-1.amazonaws.com/staging',
  dev: 'wss://5hloccdggd.execute-api.us-east-1.amazonaws.com/staging',
};
// eslint-disable-next-line no-undef
const env = process.env.REACT_APP_API_ENV;

/**
 * Setting BaseUrl
 *
 * If env vars have been set by envconsul it will use that.
 * Otherwise it will look for .env file with the REACT_APP_API_ENV, if not present it will default to localhost
 * */

const DCABaseUrl = DCAurls.local;
const TangraDCABaseUrl = DCAurls.dev;
const CwbBaseurls = CWBurls.stack_url;
const tangraBaseURL = tangraURLs.stack_url; //env ? tangraURLs[env] : tangraURLs.dev;
const socketBaseUrl = env ? SocketURL[env] : SocketURL.dev;
const cboBaseURL = CBOurls.stack_url;
const cboLocalStackURL = CBOurls['localStack'];

export const AppSettings = {
  CBOServer: cboBaseURL,
  DCAserver: DCABaseUrl,
  TangraDCAserver: TangraDCABaseUrl,
  cwbServer: CwbBaseurls,
  DCPServer: DCPurls,
  tangraURL: tangraBaseURL,
  logging: { logLevel: LogLevel.Errors },
  socketURL: socketBaseUrl,
  cboLocalServer: cboLocalStackURL,
};

export const QuickLinksURL = {
  deloitte: 'https://www.deloitte.com/',
  deloitteCloudEngineering:
    'https://www2.deloitte.com/global/en/pages/strategy-operations/solutions/cloud-engineering.html',
  cmsPortal: 'https://www.cms.com/',
  swagger: 'https://app.swaggerhub.com/apis-docs/soadity/icp-tangra/1.0.0#/',
  dcpKaibur: 'https://dcp.deloitte.dcpgreendot.com/',
  runTalkQE: 'http://talkqe-alb-2019878816.us-east-1.elb.amazonaws.com/TWB/#/',
  runCofta:
    'https://appstream2.us-east-1.aws.amazon.com/authenticate?parameters=eyJ0eXBlIjoiRU5EX1VTRVIiLCJleHBpcmVzIjoiMTY2MjU2OTkxMSIsImF3c0FjY291bnRJZCI6IjU1NTc2OTc5MTgyNCIsInVzZXJJZCI6ImFrc2hjaG91ZGhhcnlAZGVsb2l0dGUuY29tIiwiY2F0YWxvZ1NvdXJjZSI6InN0YWNrL3Rlc3QyMmF1ZyIsImZsZWV0UmVmIjoiZmxlZXQvZmxlZXQtMjJhdWciLCJhcHBsaWNhdGlvbklkIjoiIiwidXNlckNvbnRleHQiOiIiLCJtYXhVc2VyRHVyYXRpb25JblNlY3MiOiI1NzYwMCJ9&signature=%2BgiavDJQm5jJFiw0yzl%2FEW%2F1ya9ZQCHw%2BJk2UAFa7rU%3D',
  splunkDashboard: 'http://splunk-lb-1636059872.us-east-1.elb.amazonaws.com',
  splunkDashboardGCP: 'http://34.107.238.204',
};
