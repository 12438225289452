import React, { useState, useEffect } from 'react';
import Loader from '../../../components/Loader/Loader';
import '../TruNorthDashboard/TruNorthDashboard.less';
import { formatUrl, handleZoom } from '../../../libs/helper/HelperFunctions';
import {
  IVisitedPagePayload,
  SectionType,
  TrafficAnalyticsActions,
} from '../../../libs/store/actions/WebAnalytics/TrafficAnalyticsActions';
import { useDispatch } from 'react-redux';
import { IProduct } from '../../../libs/models/cbo/EngagementModels';
import { trunorthDecomposeDemoInstanceConfig } from '../KeyAssetsConstant';

interface IAssetProps {
  selectedAssetConfig?: IProduct;
  isEngagement?: boolean;
  selectedEnagagementName?: string;
  selectedEnagagementId?: string;
  selectedClientName?: string;
}

const CBOTruNorthDecomposeDashboard = ({
  isEngagement,
  selectedAssetConfig,
  selectedEnagagementName,
  selectedEnagagementId,
  selectedClientName,
}: IAssetProps) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  const dispatcher = {
    postPageVisited: (data: IVisitedPagePayload) =>
      dispatch(TrafficAnalyticsActions.postVisitedPageStart(data)),
  };

  useEffect(() => {
    handleZoom('assess-iframe');
    dispatcher.postPageVisited({
      sectionType: isEngagement ? SectionType.ENG_ASSET : SectionType.PURSUIT_ASSET,
      assetName: SectionType.TRUNORTHDECOMPOSE,
      name: selectedEnagagementName,
      id: selectedEnagagementId,
      clientName: selectedClientName,
    });
    return () => {
      dispatcher.postPageVisited({
        sectionType: isEngagement ? SectionType.ENG_ASSET : SectionType.PURSUIT_ASSET,
        assetName: SectionType.TRUNORTHDECOMPOSE,
        name: selectedEnagagementName,
        id: selectedEnagagementId,
        clientName: selectedClientName,
      });
    };
  }, []);

  const getIframeUrl = (instanceUrl?: string) => {
    return `${formatUrl(instanceUrl ?? '')}`;
  };

  return (
    <div className="trunorth-dashboard-wrapper">
      <Loader loaderName="Authorizing..." loading={isLoading}>
        <iframe
          id="assess"
          className="assess-iframe"
          title="Assess"
          src={
            isEngagement
              ? getIframeUrl(selectedAssetConfig?.instanceUrl)
              : selectedAssetConfig?.configDone
                ? getIframeUrl(selectedAssetConfig?.instanceUrl)
                : getIframeUrl(trunorthDecomposeDemoInstanceConfig.demoInstanceUrl)
          }
          onLoad={() => setIsLoading(false)}
        />
      </Loader>
    </div>
  );
};

export default CBOTruNorthDecomposeDashboard;
