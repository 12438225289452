import { put, takeEvery } from 'redux-saga/effects';
import { NotificationTypes } from '../../../../components/Notifications/NotificationsConstants';
import {
  ICapability,
  IEditCapabilityPayload,
  IFeaturesList,
  IKeyAssetsList,
} from '../../../models/cbo/CapabilityManagementModel';
import CapabilityManagementService from '../../../services/cbo/CapabilityManagementService/CapabilityManagementService';
import engagementService from '../../../services/cbo/EnagementService/EngagementService';
import {
  CapabilityManagementAction,
  CapabilityManagementActions,
  CapabilityActionTypes,
} from '../../actions/cbo/CapabilityManagementActions';
import { ToastActions } from '../../actions/ToastActions';

export function* getCapabilityDataWorker() {
  try {
    const response: ICapability[] = yield CapabilityManagementService.getCapabilityList();
    yield put(CapabilityManagementActions.getCapabilityDataSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(CapabilityManagementActions.getCapabilityDataError(error));
      yield put(
        ToastActions.showToast({
          message: 'Error!!!',
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}

export function* updateCapabilityDataWorker(action: CapabilityManagementAction) {
  try {
    const { data } = action;
    yield CapabilityManagementService.patchCapabilityData(data as IEditCapabilityPayload);
    yield put(
      ToastActions.showToast({
        description: 'Capability updated successfully',
        type: NotificationTypes.SUCCESS,
      }),
    );
    try {
      const capabilityList: ICapability[] = yield CapabilityManagementService.getCapabilityList();
      yield put(CapabilityManagementActions.getCapabilityDataSuccess(capabilityList));
    } catch (error) {
      if (error instanceof Error) {
        yield put(CapabilityManagementActions.getCapabilityDataError(error));
      }
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(CapabilityManagementActions.updateCapabilityDataError(error));
      yield put(
        ToastActions.showToast({
          message: 'Error!!!',
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}

export function* addCapabilityDataWorker(action: CapabilityManagementAction) {
  try {
    const { data } = action;
    yield CapabilityManagementService.addCapabilityData(data as IEditCapabilityPayload);
    yield put(
      ToastActions.showToast({
        description: 'Capability added successfully',
        type: NotificationTypes.SUCCESS,
      }),
    );
    try {
      const capabilityList: ICapability[] = yield CapabilityManagementService.getCapabilityList();
      yield put(CapabilityManagementActions.getCapabilityDataSuccess(capabilityList));
    } catch (error) {
      if (error instanceof Error) {
        yield put(CapabilityManagementActions.getCapabilityDataError(error));
      }
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(CapabilityManagementActions.addCapabilityDataError(error));
      yield put(
        ToastActions.showToast({
          message: 'Error!!!',
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}

export function* getKeyAssetsDataWorker() {
  try {
    const response: IKeyAssetsList[] = yield CapabilityManagementService.getKeyAssetsList();
    yield put(CapabilityManagementActions.getKeyAssetsDataSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(CapabilityManagementActions.getKeyAssetsDataError(error));
      yield put(
        ToastActions.showToast({
          message: 'Error!!!',
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}

export function* updateKeyAssetsDataWorker(action: CapabilityManagementAction) {
  try {
    const { data } = action;
    yield CapabilityManagementService.updateKeyAssetsData(data as IKeyAssetsList);
    yield put(
      ToastActions.showToast({
        description: 'Asset updated successfully',
        type: NotificationTypes.SUCCESS,
      }),
    );
    try {
      const response: IKeyAssetsList[] = yield CapabilityManagementService.getKeyAssetsList();
      yield put(CapabilityManagementActions.getKeyAssetsDataSuccess(response));
    } catch (error) {
      if (error instanceof Error) {
        yield put(CapabilityManagementActions.getKeyAssetsDataError(error));
      }
    }
    yield put(CapabilityManagementActions.updateCapabilityDataSuccess());
  } catch (error) {
    if (error instanceof Error) {
      yield put(CapabilityManagementActions.updateCapabilityDataError(error));
      yield put(
        ToastActions.showToast({
          message: 'Error!!!',
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}

export function* getFeaturesDataWorker() {
  try {
    const response: IFeaturesList[] = yield CapabilityManagementService.getFeaturesList();
    yield put(CapabilityManagementActions.getFeaturesDataSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(CapabilityManagementActions.getFeaturesDataError(error));
      yield put(
        ToastActions.showToast({
          message: 'Error!!!',
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}

export function* capabilityManagementWatcher() {
  yield takeEvery(CapabilityActionTypes.GET_CAPABILITY_DATA_STARTED, getCapabilityDataWorker);
  yield takeEvery(CapabilityActionTypes.UPDATE_CAPABILITY_DATA_STARTED, updateCapabilityDataWorker);
  yield takeEvery(CapabilityActionTypes.GET_KEY_ASSETS_DATA_STARTED, getKeyAssetsDataWorker);
  yield takeEvery(CapabilityActionTypes.UPDATE_KEY_ASSETS_DATA_STARTED, updateKeyAssetsDataWorker);
  yield takeEvery(CapabilityActionTypes.GET_FEATURES_DATA_STARTED, getFeaturesDataWorker);
  yield takeEvery(CapabilityActionTypes.ADD_CAPABILITY_DATA_STARTED, addCapabilityDataWorker);
}
