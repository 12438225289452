import React, { useState, useEffect } from 'react';
import Loader from '../../../components/Loader/Loader';
import { formatUrl, handleZoom } from '../../../libs/helper/HelperFunctions';
import {
  IVisitedPagePayload,
  SectionType,
  TrafficAnalyticsActions,
} from '../../../libs/store/actions/WebAnalytics/TrafficAnalyticsActions';
import { useDispatch } from 'react-redux';
import { IProduct } from '../../../libs/models/cbo/EngagementModels';
import { OperateEdgeDemoInstanceConfig } from '../KeyAssetsConstant';
import { useAppSelector, IApplicationState } from '../../../libs/store/reducers';
import { featureNames } from '../../CBOEngagementSummary/CBOEngagementSummaryConstants';

interface IAssetProps {
  selectedAssetConfig?: IProduct;
  isEngagement?: boolean;
  selectedEnagagementName?: string;
  selectedEnagagementId?: string;
  isViewMore?: boolean;
  selectedClientName?: string;
}

const OperateEdgeDashboard = ({
  isEngagement,
  selectedAssetConfig,
  selectedEnagagementName,
  selectedEnagagementId,
  isViewMore,
  selectedClientName,
}: IAssetProps) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const dispatcher = {
    postPageVisited: (data: IVisitedPagePayload) =>
      dispatch(TrafficAnalyticsActions.postVisitedPageStart(data)),
  };

  const { selectedFeature } = useAppSelector((state: IApplicationState) => ({
    selectedFeature: state.cboViewSummary.selectedFeature,
  }));
  useEffect(() => {
    handleZoom('assess-iframe');
    dispatcher.postPageVisited({
      sectionType: isEngagement ? SectionType.ENG_ASSET : SectionType.PURSUIT_ASSET,
      assetName: SectionType.OPERATEEDGE,
      name: selectedEnagagementName,
      id: selectedEnagagementId,
      clientName: selectedClientName,
    });
    return () => {
      dispatcher.postPageVisited({
        sectionType: isEngagement ? SectionType.ENG_ASSET : SectionType.PURSUIT_ASSET,
        assetName: SectionType.OPERATEEDGE,
        name: selectedEnagagementName,
        id: selectedEnagagementId,
        clientName: selectedClientName,
      });
    };
  }, []);

  const getFeatureRoute = () => {
    switch (selectedFeature) {
      case featureNames?.problemInsights:
        return 'problem-insights/dashboard';

      case featureNames.incidentDetails:
      case featureNames.incidentDetails:
      case featureNames.incidentSummaryByStatus:
        return 'service/incident';

      case featureNames.learningCenter:
        return 'report/dashboard';
      case featureNames?.transitions:
        return 'transition/tdashboard';
      default:
        return '';
    }
  };
  const getIframeUrl = () => {
    return selectedAssetConfig?.configDone
      ? `${formatUrl(selectedAssetConfig?.instanceUrl ?? '')}/OPI.OE/app/${getFeatureRoute()}`
      : `${formatUrl(
          OperateEdgeDemoInstanceConfig.demoInstanceUrl ?? '',
        )}/OPI.OE/app/${getFeatureRoute()}`;
  };

  return (
    <div className="trunorth-dashboard-wrapper">
      <Loader loaderName="Authorizing..." loading={isLoading}>
        <iframe
          id="assess"
          className="assess-iframe"
          title="Assess"
          src={
            !isViewMore
              ? selectedAssetConfig?.configDone
                ? selectedAssetConfig?.instanceUrl
                : OperateEdgeDemoInstanceConfig.demoInstanceUrl
              : getIframeUrl()
          }
          onLoad={() => setIsLoading(false)}
        />
      </Loader>
    </div>
  );
};

export default OperateEdgeDashboard;
