import { IProduct } from '../../libs/models/cbo/EngagementModels';

export const truNorthModernizationCentralDemoInstanceConfig = {
  demoInstanceUrl: 'https://corebusinessone.trunorth.deloitte.com/', //'https://nightly.trunorth.deloitte.com/'
  healthCheckUrl: 'https://corebusinessone.trunorth.deloitte.com/core/actuator/health', //'https://nightly.trunorth.deloitte.com/core/actuator/health', //
  portfolioid: '712144a4-e09d-4bc0-b152-49839de9381c', //'42725702-547f-4f00-9dea-d426d8edb3d8', //
  portfolioName: 'Core Business One Demo', // 'CBO Demo', //
  projectName: 'Cloud Optimization', //'Datacenter Exit',
  realm: 'TruNorth', //'TruNorth'
  authDomainUrl: 'https://auth.trunorth.deloitte.com',
  demoJiraInstanceUrl: 'https://iriseu.deloitte.com/projects/CBOPCM',
};

export const truNorthCodeDiscoveryDemoInstanceConfig = {
  demoInstanceUrl: 'https://appmodil.deloitte.com/mining', //'https://mining.amm.us.deloitte.com/sandbox/',
  projectId: '1', //3
  apiProjectId: '8',
};

export const talkQEDemoInstanceConfig = {
  demoInstanceUrl: 'https://demotalkqe.deloitte.com',
};

export const digitalTesterDemoInstanceConfig = {
  demoInstanceUrl: 'https://qadt.deloitte.com',
};

export const dcaDemoInstanceConfig = {
  demoInstanceUrl: 'https://cbo.dca.deloitte.com/', //old url https://dev.dca.deloitte.com/login#cboone
};

export const cwbemoInstanceConfig = {
  demoInstanceUrl: 'https://cwb.deloitte.com/',
};

export const OperateEdgeDemoInstanceConfig = {
  demoInstanceUrl: 'https://preprod-operateedge.deloitte.com/',
  appId: '30563',
};

export const apiVaultDemoInstanceConfig = {
  demoInstanceUrl: 'https://deloittetouchellp30community.force.com/s/',
};

export const cb360DemoInstanceConfig = {
  demoInstanceUrl: 'https://demo-qa-cb360.us.deloitte.com', //'https://qasite-cb360.us.deloitte.com/',
  demoAccessTokenAPIUrl: 'https://demo-qa-apigw-cb360.us.deloitte.com', //'https://qasite-apigw-cb360.us.deloitte.com/authz/auth/token',
};

export const cmsDemoInstanceConfig = {
  demoInstanceUrl: 'https://deloitteauthqa.opencloudportal.com/',
};

export const depGitHubPilotInstanceConfig = {
  demoInstanceUrl: 'https://github.com/Deloitte-US-Consulting/dep-demo',
};

export const depKuberneteServicesInstanceConfig = {
  demoInstanceUrl: 'https://github.com/Deloitte-US-Engineering/ska-core',
};

export const SmartPMODemoInstanceConfig = {
  demoInstanceUrl: 'https://app.smartsheet.com',
};

export const depDemoInstanceConfig = {
  demoInstanceUrl: 'https://engineeringplatforms.deloitte.com', // old URL 'https://dep.test.deloitteinnovation.us',
  demoGitRepoList: ['dep-demo'],
  demoKMSInstanceUrl:
    'https://kms-cbone-main-use1.cbone.eac-apilot.dep.deloitteinnovation.us/argocd',
};

export const processBionicsConfig = {
  demoInstanceUrl:
    'https://deloitte-us-partner-sandbox.us-1.celonis.cloud/package-manager/ui/views/ui/spaces/d8a24dcf-f864-47e2-a22c-f9ff7d315dac/nodes/40122610-cba3-4b6a-9c0d-0bb250f7a02c?inventory-network-analysis--tab-column_2ce1d4fd-abd9-47a6-8418-038b1f9c4237-0=tab-6927208d-c126-4be9-94a5-1fedc995c988',
};
export const DigitalMaturityDiagnosticConfig = {
  viewMoreUrl: 'https://resources.deloitte.com/sites/global/Services/ft/Pages/DMAT.aspx',
};
export const automatedVUCAAssessmentConfig = {
  viewMoreUrl: 'https://app.smartsheet.com/folders/9F8jQPPCX7HxCmxQfjpj7hQFvMp7HmrMGgCfC6f1',
};

export const AIAssistConfig = {
  demoJiranstanceUrl: 'https://iriseu.deloitte.com/projects/CBOPCM',
};

export const trunorthDecomposeDemoInstanceConfig = {
  demoInstanceUrl: 'https://db-cutter.amm.us.deloitte.com/prod',
  demoJiraInstanceUrl: 'https://iriseu.deloitte.com/projects/CBOPCM',
};
