import {
  ICapability,
  IEditCapabilityPayload,
  IFeaturesList,
  IKeyAssetsList,
} from '../../../models/cbo/CapabilityManagementModel';
import { API_ROUTE_CBO } from '../../ApiRoute';
import ApiService from '../../ApiService';
import ApiServiceBase from '../../ApiServiceBase';
import { ServerType, ServiceType } from '../../ServiceType.data';

export class CapabilityManagementService {
  private readonly cboService: ApiServiceBase = new ApiService(ServiceType.None, ServerType.cbo);
  private readonly localService: ApiServiceBase = new ApiService(
    ServiceType.None,
    ServerType.localStack,
  );

  public getCapabilityList(): Promise<ICapability[]> | ICapability[] {
    const route: string[] = [`${API_ROUTE_CBO.GET_CAPABILITY}`];
    return this.cboService.get({ route: route });
  }

  public patchCapabilityData(body: IEditCapabilityPayload): Promise<ICapability> | ICapability {
    const route: string[] = [`${API_ROUTE_CBO.SAVE_CAPABILITY}`];
    return this.cboService.patch({ route: route }, body);
  }
  public addCapabilityData(body: IEditCapabilityPayload): Promise<ICapability> | ICapability {
    const route: string[] = [`${API_ROUTE_CBO.SAVE_CAPABILITY}`];
    return this.cboService.post({ route: route }, body);
  }

  public getKeyAssetsList(): Promise<IKeyAssetsList[]> | IKeyAssetsList[] {
    const route: string[] = [`${API_ROUTE_CBO.GET_KEY_ASSETS}`];
    return this.cboService.get({ route: route });
  }

  public updateKeyAssetsData(body: IKeyAssetsList): Promise<IKeyAssetsList> | IKeyAssetsList {
    const route: string[] = [`${API_ROUTE_CBO.GET_KEY_ASSETS}`];
    return this.cboService.patch({ route: route }, body);
  }

  public getFeaturesList(): Promise<IFeaturesList[]> | IFeaturesList[] {
    const route: string[] = [`${API_ROUTE_CBO.GET_FEATURES}`];
    return this.cboService.get({ route: route });
  }
}

const capabilityManagementService = new CapabilityManagementService();
export default capabilityManagementService;
