import { Reducer } from 'redux';
import CapabilityManagementState from '../../state/cbo/CapabilityManagementState';
import { ICapability, IKeyAssetsList } from '../../../models/cbo/CapabilityManagementModel';
import {
  CapabilityManagementAction,
  CapabilityActionTypes,
} from '../../actions/cbo/CapabilityManagementActions';
import { IFeatureList } from '../../../models/cbo/EngagementModels';

export const initialState: CapabilityManagementState = {
  capabilityListData: [],
  loading: false,
  error: '',
  keyAssetsListData: [],
  featuresList: [],
};

export const CapabilityManagementReducer: Reducer<CapabilityManagementState> = (
  state = initialState,
  action: CapabilityManagementAction,
) => {
  switch (action.type) {
    case CapabilityActionTypes.GET_CAPABILITY_DATA_STARTED:
      return {
        ...state,
        loading: true,
        error: undefined,
        capabilityListData: [],
      };
    case CapabilityActionTypes.GET_CAPABILITY_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        capabilityListData: action.data as ICapability[],
      };
    case CapabilityActionTypes.GET_CAPABILITY_DATA_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data,
      };

    case CapabilityActionTypes.UPDATE_CAPABILITY_DATA_STARTED:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case CapabilityActionTypes.UPDATE_CAPABILITY_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
      };
    case CapabilityActionTypes.UPDATE_CAPABILITY_DATA_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data,
      };

    case CapabilityActionTypes.GET_KEY_ASSETS_DATA_STARTED:
      return {
        ...state,
        loading: true,
        error: undefined,
        keyAssetsListData: [],
      };
    case CapabilityActionTypes.GET_KEY_ASSETS_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        keyAssetsListData: action.data as IKeyAssetsList[],
      };
    case CapabilityActionTypes.GET_KEY_ASSETS_DATA_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data as Error,
      };

    case CapabilityActionTypes.UPDATE_KEY_ASSETS_DATA_STARTED:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case CapabilityActionTypes.UPDATE_KEY_ASSETS_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
      };
    case CapabilityActionTypes.UPDATE_KEY_ASSETS_DATA_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data,
      };

    case CapabilityActionTypes.GET_FEATURES_DATA_STARTED:
      return {
        ...state,
        loading: true,
        error: undefined,
        featuresList: [],
      };
    case CapabilityActionTypes.GET_FEATURES_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        featuresList: action.data as IFeatureList[],
      };
    case CapabilityActionTypes.GET_FEATURES_DATA_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data,
      };

    case CapabilityActionTypes.ADD_CAPABILITY_DATA_STARTED:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case CapabilityActionTypes.ADD_CAPABILITY_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
      };
    case CapabilityActionTypes.ADD_CAPABILITY_DATA_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data,
      };

    default:
      return state;
  }
};
