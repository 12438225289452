import { Reducer } from 'redux';
import CBOAppHeader from '../../../../containers/CBOAppHeader/CBOAppHeader';
import {
  CboViewSummaryAction,
  CboViewSummaryActionTypes,
} from '../../actions/cbo/CboViewSummaryAction';

import CboViewSummaryState, {
  ICapabilitiesSequence,
  ICapabilityReorderPayload,
  IViewSummaryResponsePayload,
} from '../../state/cbo/CboViewSummaryState';

export const initialState: CboViewSummaryState = {
  selectedPhase: '',
  selectedFeature: '',
  selectedAsset: '',
  isEngagement: false,
  selectedClientId: '',
  selectedClientName: '',
  selectedEnagagementId: '',
  selectedEngagementClientLogo: '',
  selectedEnagagementName: '',
  error: '',
  loading: false,
};

export const cboViewSummaryReducer: Reducer<CboViewSummaryState> = (
  state = initialState,
  action: CboViewSummaryAction,
) => {
  switch (action.type) {
    case CboViewSummaryActionTypes.SET_IS_ENAGEMENT:
      return {
        ...state,
        isEngagement: action.data as boolean,
      };
    case CboViewSummaryActionTypes.SET_SELECTED_PHASE:
      return {
        ...state,
        selectedPhase: action.data as string,
      };
    case CboViewSummaryActionTypes.SET_SELECTED_FEATURE:
      return {
        ...state,
        selectedFeature: action.data as string,
      };
    case CboViewSummaryActionTypes.SET_SELECTED_ASSET:
      return {
        ...state,
        selectedAsset: action.data as string,
      };

    case CboViewSummaryActionTypes.SET_SELECTED_ENAGEMENT_ID:
      return {
        ...state,
        selectedEnagagementId: action.data as string,
      };
    case CboViewSummaryActionTypes.SET_SELECTED_ENGAGEMENT_CLIENT_LOGO:
      return {
        ...state,
        selectedEngagementClientLogo: action.data as string,
      };
    case CboViewSummaryActionTypes.RESET_SELECTED_ENAGEMENT_ID:
      return {
        ...state,
        selectedEnagagementId: '',
      };
    case CboViewSummaryActionTypes.SET_SELECTED_ENAGEMENT_NAME:
      return {
        ...state,
        selectedEnagagementName: action.data as string,
      };
    case CboViewSummaryActionTypes.SET_SELECTED_CLIENT_ID:
      return {
        ...state,
        selectedClientId: action.data as string,
      };
    case CboViewSummaryActionTypes.SET_SELECTED_CLIENT_NAME:
      return {
        ...state,
        selectedClientName: action.data as string,
      };

    case CboViewSummaryActionTypes.POST_CAPABILITY_ORDER_STARTED:
      return {
        ...state,
        loading: true,
      };

    case CboViewSummaryActionTypes.POST_CAPABILITY_ORDER_SUCCESS:
      return {
        ...state,
        capabilities: action.data as ICapabilitiesSequence[],
        loading: true,
      };

    case CboViewSummaryActionTypes.POST_CAPABILITY_ORDER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data as Error,
      };
    case CboViewSummaryActionTypes.PATCH_FEATURE_UPDATE_STARTED:
      return {
        ...state,
        loading: true,
      };
    case CboViewSummaryActionTypes.PATCH_FEATURE_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case CboViewSummaryActionTypes.PATCH_FEATURE_UPDATE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case CboViewSummaryActionTypes.RESET_SELECTED_CLIENT_NAME:
      return {
        ...state,
        selectedClientName: '',
      };
    default:
      return state;
  }
};
