import { IConfigurePayload } from '../../../models/cbo/EngagementModels';
import { IFeatureUpdatePayload } from '../../../models/cbo/EngagementSummaryGraphModel';
import {
  ICapabilitiesSequence,
  ICapabilityReorderPayload,
  IViewSummaryResponsePayload,
} from '../../state/cbo/CboViewSummaryState';
import { IStoreActionWithFlags } from '../../StoreHelper';

export enum CboViewSummaryActionTypes {
  SET_SELECTED_ENAGEMENT_ID = 'SET_SELECTED_ENAGEMENT_ID',
  SET_SELECTED_ENAGEMENT_NAME = 'SET_SELECTED_ENAGEMENT_NAME',
  SET_IS_ENAGEMENT = 'SET_IS_ENAGEMENT',
  SET_SELECTED_PHASE = 'SET_SELECTED_PHASE',
  SET_SELECTED_FEATURE = 'SET_SELECTED_FEATURE',
  SET_SELECTED_ASSET = 'SET_SELECTED_ASSET',
  SET_SELECTED_ENGAGEMENT_CLIENT_LOGO = 'SET_SELECTED_ENGAGEMENT_CLIENT_LOGO',
  RESET_SELECTED_ENAGEMENT_ID = 'RESET_SELECTED_ENAGEMENT_ID',
  POST_CAPABILITY_ORDER_STARTED = 'POST_CAPABILITY_ORDER_STARTED',
  POST_CAPABILITY_ORDER_SUCCESS = 'POST_CAPABILITY_ORDER_SUCCESS',
  POST_CAPABILITY_ORDER_ERROR = 'POST_CAPABILITY_ORDER_ERROR',
  PATCH_FEATURE_UPDATE_STARTED = 'PATCH_FEATURE_UPDATE_STARTED',
  PATCH_FEATURE_UPDATE_SUCCESS = 'PATCH_FEATURE_UPDATE_SUCCESS',
  PATCH_FEATURE_UPDATE_ERROR = 'PATCH_FEATURE_UPDATE_ERROR',
  SET_SELECTED_CLIENT_ID = 'SET_SELECTED_CLIENT_ID',
  SET_SELECTED_CLIENT_NAME = 'SET_SELECTED_CLIENT_NAME',
  RESET_SELECTED_CLIENT_NAME = 'RESET_SELECTED_CLIENT_NAME',
}

export type CboViewSummaryActionPayload =
  | boolean
  | string
  | Error
  | IViewSummaryResponsePayload
  | ICapabilityReorderPayload
  | ICapabilitiesSequence[]
  | IFeatureUpdatePayload
  | IConfigurePayload;

export type CboViewSummaryAction = IStoreActionWithFlags<
  CboViewSummaryActionTypes,
  CboViewSummaryActionPayload,
  Record<string, boolean>
>;

export class CboViewSummaryActions {
  public static setIsEngagement(data: boolean): CboViewSummaryAction {
    return {
      type: CboViewSummaryActionTypes.SET_IS_ENAGEMENT,
      data,
    };
  }

  public static setSelectedPhase(data: string): CboViewSummaryAction {
    return {
      type: CboViewSummaryActionTypes.SET_SELECTED_PHASE,
      data,
    };
  }
  public static setSelectedFeature(data: string): CboViewSummaryAction {
    return {
      type: CboViewSummaryActionTypes.SET_SELECTED_FEATURE,
      data,
    };
  }

  public static setSelectedAsset(data: string): CboViewSummaryAction {
    return {
      type: CboViewSummaryActionTypes.SET_SELECTED_ASSET,
      data,
    };
  }

  public static setSelectedEngagementId(data: string): CboViewSummaryAction {
    return {
      type: CboViewSummaryActionTypes.SET_SELECTED_ENAGEMENT_ID,
      data,
    };
  }
  public static setSelectedEngagementName(data: string): CboViewSummaryAction {
    return {
      type: CboViewSummaryActionTypes.SET_SELECTED_ENAGEMENT_NAME,
      data,
    };
  }
  public static setSelectedClientId(data: string): CboViewSummaryAction {
    return {
      type: CboViewSummaryActionTypes.SET_SELECTED_CLIENT_ID,
      data,
    };
  }
  public static setSelectedClientName(data: string): CboViewSummaryAction {
    return {
      type: CboViewSummaryActionTypes.SET_SELECTED_CLIENT_NAME,
      data,
    };
  }
  public static setSelectedClientLogo(data: string): CboViewSummaryAction {
    return {
      type: CboViewSummaryActionTypes.SET_SELECTED_ENGAGEMENT_CLIENT_LOGO,
      data,
    };
  }
  public static resetSelectedEngagementId(): CboViewSummaryAction {
    return {
      type: CboViewSummaryActionTypes.RESET_SELECTED_ENAGEMENT_ID,
    };
  }

  public static PostCapabilityOrderStart(data: ICapabilityReorderPayload): CboViewSummaryAction {
    return {
      type: CboViewSummaryActionTypes.POST_CAPABILITY_ORDER_STARTED,
      data,
    };
  }
  public static PostCapabilityOrderSuccess(data: ICapabilitiesSequence[]): CboViewSummaryAction {
    return {
      type: CboViewSummaryActionTypes.POST_CAPABILITY_ORDER_SUCCESS,
      data,
    };
  }
  public static PostCapabilityOrderError(error: Error): CboViewSummaryAction {
    return {
      type: CboViewSummaryActionTypes.POST_CAPABILITY_ORDER_ERROR,
      data: error,
    };
  }

  public static patchFeatureUpdateStart(data: IFeatureUpdatePayload): CboViewSummaryAction {
    return {
      type: CboViewSummaryActionTypes.PATCH_FEATURE_UPDATE_STARTED,
      data,
    };
  }
  public static patchFeatureUpdateSuccess(): CboViewSummaryAction {
    return {
      type: CboViewSummaryActionTypes.PATCH_FEATURE_UPDATE_SUCCESS,
    };
  }
  public static patchFeatureUpdateError(error: Error): CboViewSummaryAction {
    return {
      type: CboViewSummaryActionTypes.PATCH_FEATURE_UPDATE_ERROR,
      data: error,
    };
  }
  public static resetSelectedClientName(): CboViewSummaryAction {
    return {
      type: CboViewSummaryActionTypes.RESET_SELECTED_CLIENT_NAME,
    };
  }
}
